export function swiperCustomPagination(current, total) {
  let paginationContent = '<div>';

  for (let i = 1; i <= total; i++) {
    let barClassName = 'swiper-pagination__item';

    if (i === 1) {
      paginationContent += `<span class="swiper-pagination__number swiper-pagination__number--first">0${i}</span>`;
    }

    if (current === i) {
      barClassName += ' swiper-pagination__item--active';
    }

    const bar = `<span class="${barClassName} dot"></span>`;

    paginationContent += bar;

    if (i === total) {
      let index = i;

      if (2 > index.toString().length) {
        index = `0${i}`;
      }

      paginationContent += `<span class="swiper-pagination__number swiper-pagination__number--last">${index}</span>`;
    }
  }
  paginationContent += '</div>';

  return paginationContent;
}
