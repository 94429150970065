import Swiper from 'swiper';
import { swiperCustomPagination } from '../../../assets/scripts/swiper-pagination';
// init Swiper:
new Swiper('.posts-carousel__carousel', {
  slidesPerView: 'auto',
  rewind: true,
  spaceBetween: 32,
  watchOverflow: true,
  navigation: {
    nextEl: '.posts-carousel__navigation .swiper-button-next',
    prevEl: '.posts-carousel__navigation .swiper-button-prev',
  },
  pagination: {
    el: '.posts-carousel__navigation .swiper-pagination',
    clickable: true,
    type: 'custom',
    renderCustom: (swiper, current, total) =>
      swiperCustomPagination(current, total),
  },
});
